import { useAppStore } from "@/store/app";

/**
 * @description 跳转链接补全
 */
export function httpCompletion(url: string) {
  if (['http://', 'https://'].some(item => url.includes(item)))
    return url;
  return `https://${url}`;
}

/**
 * 获取url的参数
 */
export function getUrlParams(url: string) {
  try {
    const params = new URL(url).searchParams;
    const paramsObj: Record<string, any> = {};
    for (const [key, value] of params.entries()) {
      paramsObj[key] = value;
    }
    return paramsObj;
  } catch (error) {
    console.error('Invalid URL:', url);
    window.location.reload(true);
    return {};
  }
}

/**
 * 获取url的路径
 */
/**
 * 获取url的路径
 */
export function getUrlPath(url: string) {
  try {
    return new URL(url).pathname;
  } catch (error) {
    console.error('Invalid URL:', url);
    window.location.reload(true);
    return '';
  }
}

/**
 * @description 谷歌浏览器跳转地址(android)
 * @param boolean fallback 是否使用备用地址
 */
export function intentChrome() {
  const appStore = useAppStore();
  const protocol = location.protocol.replace(':', '');
  const port = location.port ? `:${location.port}` : '';
  const query = location.href.includes('?') ? `?${location.href.split('?')[1]}` : '';
  let intentURL = `${location.hostname}${port}${query}`;

  if (appStore.token) {
    if (intentURL.includes('?')) {
      intentURL += '&token=' + appStore.token;
    } else {
      intentURL += '?token=' + appStore.token;
    }
  }

  return `intent://${intentURL}#Intent;scheme=${protocol};package=com.android.chrome;end`
}
