<!-- 第三方登录 -->
<template>
	<div v-if="thirdPartyLoginTypes.length" class="text-center px-12">
      <!-- 分割线 -->
      <div class="w-full flex-center py-[0.625rem]">
        <div class="w-full h-[0.0625rem] divider-color " />
        <span class="color-text-40 text-xs px-[0.625rem]">{{ 'OR' }}</span>
        <div class="w-full h-[0.0625rem] divider-color " />
      </div>
      <div class="w-full flex-center mb-[0.625rem]">
        <IonIcon class="mx-3 text-5xl" style="color: black" :src="`/first/svg/login/${item.item}.svg`"
          v-for="item in thirdPartyLoginTypes" :key="item.item" @click="thirdPartyLoginHandle(item)"/>
      </div>
    </div>
</template>

<script setup lang="ts">
import { IonIcon } from "@ionic/vue";
import useThirdPartyAuthLogic from "@/components/loginModal/thirdPartyAuthLogic";

const {
  thirdPartyLoginTypes,
  thirdPartyLoginHandle,
} = useThirdPartyAuthLogic();
</script>

<style scoped lang="less">
@import "@/components/loginModal/styles/register.less";
</style>
