import useFacebooklogin from "@/hooks/useFacebooklogin";
import { switchTab } from "@/router/hooks";
import { BROWSER } from "@/enums/device";
import router from "@/router";
export default (token: string) => {
  const appStore = useAppStore(); // 全局store
  const systemStore = useSystemStore(); // 系统store
  const route = router.currentRoute
  setTimeout(async () => {
    await appStore.setToken(token);
    const loginRedirectMap = {
      [BROWSER.MOBILE_SAFARI]: () => {
        router.replace("/launch").then(() => {
          location.reload();
        });
      },
      ['Facebook']: () => {
        useFacebooklogin(token);
      },
      default: () => {
        if (route.value.path !== "/main/inicio") {
          switchTab();
        }
      }
    };

    const redirectFn = loginRedirectMap[systemStore.browser as keyof typeof loginRedirectMap] || loginRedirectMap.default;
    redirectFn();
  }, 500);
}
