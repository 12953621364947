/**
 * @description: 检查字符串是否为数字组成
 * @param str 
 */
export function checkNumByStr(str: string) {
  return /^\d+$/.test(str);
}

/**
 * @description: 去除字符串中非字母内容
 */
export function removeSpecialNum(str: string) {
  return str.replace(/[^a-zA-Z]/g, '');
}