/**
 * @description 拼接url参数
 * @param params 参数对象
 */
export function buildUrlParam(params: Record<string, any>) {
  const filteredParams: Record<string, any> = {};
  for (const key in params) {
      if (params.hasOwnProperty(key) && params[key] !== null && params[key] !== undefined && params[key] !== '') {
        filteredParams[key] = params[key];
      }
  }
  const queryString = Object.keys(filteredParams).map(key => `${key}=${filteredParams[key]}`).join('&');
  return `?${queryString}`;
}