// 三方登录 逻辑层
import { useAppStore } from "@/store/app";
import { openUrl, isGray } from "@/utils/app";
import { useTenantStore } from "@/store/tenant";
import { useRoute } from "vue-router";
import { computed } from "vue";
import { addQueryParams, clearAndroidLoginInfo } from "@/utils/app"


export default function useThirdPartyAuthLogic() {
  const route = useRoute(); // 当前路由
  const appStore = useAppStore(); // 全局store
  const tenantStore = useTenantStore(); // 商户store
  const systemStore = useSystemStore(); // 系统store

  const channelId = computed(() => appStore.channelId || route.query.ch || 0); // 渠道ID
  const thirdPartyLoginTypes = computed(() => tenantStore.authInfo?.thirdPartyAuthInfo ?? []); // 第三方登录类型

  /**
   * @description 第三方登录
   */
  function thirdPartyLoginHandle(item: any) {
    clearAndroidLoginInfo();
    const redirectUrl = encodeURIComponent(addQueryParams(globalThis.location, { isApp: systemStore.isApk }));
    const url = `${item.oauthLoginUrl}${redirectUrl}${channelId.value ? `&channelId=${channelId.value}` : ""}
    ${isGray() ? "&is_gray=1" : ""}`;
    openUrl(url);
  }

  return {
    thirdPartyLoginTypes,
    thirdPartyLoginHandle,
  };
}
