import router from "@/router";
export default (token: string) => {
  const route = router.currentRoute
  const path = '/main/inicio'
  const query = {
    ...route.value.query,
    token
  }
  router.replace({ path, query })

}
