export const maxWidth = 486;
export const SPECIAL_CHAR_REGEX =/[\w\^]/; // 匹配特殊字符
// first皮肤的字体链接
export const FIRST_FONT_SIZE_LINK_LIST = [
  'https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap',
  'https://fonts.googleapis.com/css2?family=Saira:wght@400;500;700&display=swap',
  'https://fonts.googleapis.com/css2?family=Sansita+One&display=swap',
  'https://fonts.googleapis.com/css2?family=Changa+One&display=swap',

]

// second皮肤的字体链接
export const SECOND_FONT_SIZE_LINK_LIST = [
  'https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap',
  'https://fonts.googleapis.com/css2?family=Nunito:wght@800;900&display=swap',
  'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800;900&display=swap',

]
