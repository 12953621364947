// 数据校验
// 当前值是否为JSON字符串
export const isJSON = (str: string): boolean => {
	if (typeof str === 'string') {
		try {
			const obj = JSON.parse(str)
			return !!obj && typeof obj === 'object'
		} catch (e) {
			return false
		}
	}
	return false
}

// 是否为合法邮箱
export const isEmail = (email: string): boolean => {
	return /^[^\s@]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(email)
}

export function isJSONStr(value: any): boolean {
	if (typeof value === 'string') {
		return isJSON(value)
	}
	return false
}




