export type targetValueType = {
  type: string,
  info: {
    activityId: number | string,
    activityName: string
  }
}

/**
 * @description 跳转到指定的路由页面
 * @param targetValueType 跳转路由类型和活动id
 */
export function handleInlineNavigation(targetValueType: targetValueType) {
  switch (targetValueType.type) {
    case 'recharge':                 // 充值
      return '/main/entrar'
    case 'withdraw':                 // 提现
      return '/main/withdraw'
    case 'activity_list':            // 活动页面
      return '/main/promo'
    case 'promotion':                // 推广中心
      return '/spread'
    case 'vip':                      // vip
      return '/activity/vip'
    case 'home':                     // 首页
      return '/'
    case 'redeem_code':              // 兑换码
      return '/Redeem'
    case 'activity':
      return targetValueType.info?.activityId
  }
}

/**
 * @description 轮播图-转换跳转类型
 * @param targetType 跳转类型
 */
export function handleCarouselJumpType(targetType: string) {
  switch (targetType) {
    case 'internal':                  // 内跳
      return 'activity'
    case 'external':                  // 外跳
      return 'url'
    case 'none':                      // 不跳
      return 'none'
  }
}

/**
 * @description 首页侧边栏-转换跳转类型
 * @param targetType 跳转类型
 */
export function handleSidebarJumpType(targetType: string) {
  switch (targetType) {
    case 'internal':                  // 自定义
      return 'InternalLink'
    case 'external':                  // 外跳
      return 'Custom'
  }
}

/**
 * @description 首页侧边栏-valueType类型
 * @param targetType 跳转类型
 */
export function handleSideValueType(targetValueType: targetValueType) {
  if (targetValueType.info?.activityId) {
    return 'ACTIVITY'
  } else {
    return 'CODE'
  }
}
