export const toastWhiteList = [
  'vip.info',
  'channel.info',
  'auth.info',
  'tenant.info',
  'tenant.domainInfo',
  'carouselConfig.list',
  'home.hot',
  'home.list',
  'activity.list',
  'activity.config',
  'activity.rebateDetail',
  'activity.assistanceCashDetail',
  'agency.config',
  'announcement.loginOut',
  'announcement.loginIn',
  'withdraw.type',
  'withdraw.getRealName',
  'user.assets',
  'mail.noRead',
  'favorite.list',
  'avatarCount.avatarCount',
  'game.end',
]
