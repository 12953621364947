// 浏览器类型
export enum BROWSER {
  "MOBILE_SAFARI" = "Mobile Safari",
  "CHROME" = "Chrome",
  "EDGE" = "Edge",
  "FACEBOOK" = "Facebook",
  "CHROME_WEBVIEW" = "Chrome WebView",
  "WEBKIT" = "WebKit",
  "SAMSUNG_INTERNET" = "Samsung Internet",
}

// 平台环境
export enum SD_MODEL {
  "IOS_H5" = "iOSH5",
  "ANDROID_H5" = "AndroidH5",
  "PWA" = "PWA",
  "APK" = "APK",
  "IOS_APP" = "iOSApp",
  "IOS_BOOKMARK" = "iOSBookmark",
  'APK_RELEASE' = 'APKRelease',
  "DESKTOP_OS" = "DesktopOS",
  "OUT_APP_ANDROID" = "OUT_APP_ANDROID",  // 外部app android 比如facebook
  "OUT_APP_IOS" = "OUT_APP_IOS",// 外部app IOS 比如facebook
}

// 系统类型
export enum OS {
  "IOS" = "iOS",
  "ANDROID" = "Android",
}

// 平台类型
export enum PLATFORM {
  "WEB" = "web",
  "ANDROID" = "android",
  "IOS" = "ios"
}

// 社交类型
export enum SOCIAL {
  KWAI = 'Kwai',
  OPEN_URL = 'OPEN_URL',
}
